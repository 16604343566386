/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@font-face {
  font-family: Aldrich-Regular;
  src: url(assets/fonts/Aldrich-Regular.ttf) format("opentype");
}
body {
  font-family: monospace !important;
}

.bg-red {
  background: #f3b3b3 !important;
}

.bg-green {
  background: #bbffbb !important;
}

.bg-blue {
  background: #d6d6ff !important;
}

.red {
  color: red;
}

.green {
  color: green;
}

.blue {
  color: blue;
}

.orange {
  color: rgb(107, 70, 0);
  font-weight: 500;
}

.footer-bg {
  z-index: 1;
  width: 100%;
  right: 0;
  display: flex;
  position: absolute;
}

.header-bg {
  z-index: 1;
  height: 8%;
  width: 80%;
  right: 0;
  background: #ff936c;
  background: linear-gradient(45deg, #d3d1d0, #ffb392);
  box-shadow: rgba(0, 0, 0, 0.7294117647) 1px 1px 4px;
  border-radius: 10px 10px 10px 10px;
  position: absolute;
}

.menu-icon {
  position: absolute;
  bottom: 5px;
  right: 2%;
  width: 10%;
}

.card-img {
  width: 20%;
}

.appsub-container {
  height: 87%;
  height: 82%;
}

.appcontainer {
  margin-top: 60px;
  padding: 10px 10px 70px 10px;
}

.admin-card {
  font-size: 20px;
  background: #fbf7f7;
  height: 40px;
  position: relative;
  box-shadow: #b1aeae 0px 20px 5px 6px;
  margin-bottom: 5px;
}

.admin-tag {
  display: inline-block;
  background: #620037;
  color: white;
  transform: rotate(-90deg);
  top: 8px;
  font-size: 15px;
  left: -10px;
  padding: 5px;
  position: absolute;
}

.admin-space {
  display: inline-block;
  width: 8%;
}

.admin-name {
  display: inline-block;
  height: 100%;
  width: 60%;
  padding: 10px 0px;
  font-size: 15px;
}

.admin-Time {
  display: inline-block;
  font-size: 15px;
  height: 100%;
  width: 15%;
  padding: 15px 0px;
}

.admin-ticmark {
  background: green;
  color: white;
  padding: 1px;
  box-shadow: #363333 1px 2px 3px;
  margin-right: 8px;
}

.admin-worngmark {
  background: red;
  color: white;
  padding: 1px;
  box-shadow: #363333 1px 2px 3px;
}

.form-card {
  background: #edeae9;
  border-radius: 10px;
  padding: 10px;
}

.lable {
  font-weight: bold;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.form-header {
  font-size: 15px;
  color: #222224;
  font-size: 15px;
  font-weight: bold;
  -webkit-text-decoration: solid;
  text-decoration: solid;
  text-shadow: #ffad86 1px 1px 20px;
}

.form-header-new {
  font-size: 17px;
  color: #555555;
  font-weight: bolder;
  -webkit-text-decoration: solid;
  text-decoration: solid;
  text-shadow: #ff7331 1px 1px 20px;
  border-bottom: solid;
  border-color: #a7a7a7;
  border-width: 3px;
  margin-top: 10px;
  text-align: center;
  border-radius: 25px;
  width: 70%;
  margin: auto;
  align-content: center;
}

.div-header-new {
  font-size: 14px;
  color: #555555;
  font-weight: bolder;
  -webkit-text-decoration: solid;
  text-decoration: solid;
  text-shadow: #ff7331 1px 1px 20px;
  border-left: solid;
  border-right: solid;
  border-bottom: solid;
  border-color: #a7a7a7;
  border-width: 3px;
  margin-top: 10px;
  text-align: center;
  border-radius: 10px;
  width: 100%;
  margin: auto;
  align-content: center;
  background-color: #e3eef1;
}

.reject-card {
  padding: 5px;
  background: white;
  border-radius: 5px;
  box-shadow: rgba(255, 98, 98, 0.87) -2px 0px 3px 0px;
  margin-bottom: 10px;
  position: relative;
}

.card-start {
  display: inline-block;
  width: 40%;
}

.card-start .card-start-sub {
  display: flex;
  flex-direction: column;
}

.card-start .card-start-sub-text {
  font-size: 12px;
  color: #383535;
  margin-right: 10px;
  width: 250px;
}

.card-end {
  display: inline-block;
  width: 60%;
  text-align: end;
}

.accept-card {
  padding: 5px;
  position: relative;
  background: white;
  border-radius: 5px;
  box-shadow: rgba(4, 251, 114, 0.87) -2px 0px 3px 0px;
  margin-bottom: 10px;
}

.card-style {
  padding: 0px;
  margin: 5px;
  background: white;
  border-radius: 5px;
  border-width: 2px;
  box-shadow: rgb(104, 104, 104) -2px 1px 3px 1px;
  margin-bottom: 10px;
  position: relative;
  z-index: 99;
  text-align: center;
}

.allow-cr {
  white-space: pre-line;
}

.Dynamic-card-style1 {
  padding: 0px;
  margin: 5px;
  background: white;
  border-radius: 5px;
  border-width: 2px;
  box-shadow: rgb(104, 104, 104) -2px 1px 3px 1px;
  margin-bottom: 10px;
  position: relative;
  z-index: 99;
  text-align: center;
}

.pending-card {
  padding: 5px;
  background: white;
  border-radius: 5px;
  box-shadow: rgba(112, 73, 0, 0.96) -2px 0px 3px 0px;
  margin-bottom: 15px;
  position: relative;
}

.success-bg {
  background: green;
  padding: 3px;
  color: white;
  border-radius: 5px;
  /* padding: 50%; */
  margin-right: 4px;
}

.success-bg:hover {
  cursor: pointer;
}

.danger-bg:hover {
  cursor: pointer;
}

.danger-bg {
  position: relative;
  background: red;
  padding: 3px;
  color: white;
  border-radius: 5px;
  /* padding: 50%; */
  margin-right: 4px;
}

.modal-backdrop {
  width: 0px !important;
  height: 0px !important;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  background: rgba(12, 12, 12, 0.6392156863);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-content {
  margin-top: 100px;
}

.toaster-styles {
  top: 60px;
}

.badgeplain {
  position: absolute;
  top: -8px;
  background: white;
  left: 5px;
  border-radius: 2px;
  padding: -1px;
  font-weight: bold;
  font-size: smaller;
  z-index: -1;
}

.badge {
  position: absolute;
  top: -13px;
  background: #00489b;
  right: 0;
  border-radius: 2px;
  color: rgb(255, 255, 255);
  padding: 1px;
  box-shadow: rgb(163, 162, 162) 1px 1px 9px 2px;
  font-weight: lighter;
  font-size: smaller;
}

.badge-left {
  position: absolute;
  top: -13px;
  background: #ffffff;
  left: 0;
  border-radius: 2px;
  color: #00489b;
  padding: 1px;
  box-shadow: rgb(163, 162, 162) 1px 1px 9px 2px;
  font-weight: 600;
  font-size: smaller;
}

.badge-green {
  position: absolute;
  top: -13px;
  background: green;
  right: 0;
  border-radius: 2px;
  color: white;
  padding: 1px;
  box-shadow: rgb(163, 162, 162) 1px 1px 9px 2px;
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

.badge-orange {
  position: absolute;
  top: -13px;
  background: orange;
  right: 0;
  border-radius: 2px;
  color: white;
  padding: 1px;
  box-shadow: rgb(163, 162, 162) 1px 1px 9px 2px;
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

.badge-skyblue {
  position: absolute;
  top: -13px;
  background: #046891;
  right: 0;
  border-radius: 2px;
  color: white;
  padding: 1px;
  box-shadow: rgb(163, 162, 162) 1px 1px 9px 2px;
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

.badge-violet {
  position: absolute;
  top: -13px;
  background: #67089c;
  right: 0;
  border-radius: 2px;
  color: white;
  box-shadow: rgb(163, 162, 162) 1px 1px 9px 2px;
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

.badge-red {
  position: absolute;
  top: -13px;
  background: rgb(124, 3, 3);
  right: 0;
  border-radius: 2px;
  color: white;
  box-shadow: rgb(163, 162, 162) 1px 1px 9px 2px;
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

.badge-orangered {
  position: absolute;
  top: -13px;
  background: orangered;
  right: 0;
  border-radius: 2px;
  color: white;
  box-shadow: rgb(163, 162, 162) 1px 1px 9px 2px;
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

.card-Title {
  display: block;
  font-weight: 700;
}

.card-date {
  position: sticky;
  top: 0;
  display: inline-block;
  background: #55514f;
  padding: 3px;
  font-size: 0.75em;
  border-radius: 3px;
  font-weight: 700;
  color: white;
  margin-bottom: 5px;
}

ion-popover {
  --width: 280px;
  --max-height:350px;
}

.card-middle {
  text-align: end;
  position: absolute;
  top: 30px;
  right: 0;
  color: firebrick;
  font-weight: 800;
}

.dash-board-card {
  background: linear-gradient(90deg, #eeb195 0%, #f09369 35%, #c4511c 100%);
  border-radius: 10px;
  box-shadow: rgb(180, 174, 174) 1px 1px 9px 2px;
  position: relative;
}

.dash-board-card > span {
  font-size: 13px;
  font-weight: 500;
  display: flex;
  justify-content: center;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media only screen and (max-width: 990px) {
  .footer-bg {
    width: 100%;
  }
  .header {
    width: 100%;
  }
}
ion-loading.custom-loading .loading-wrapper {
  background-color: rgba(255, 255, 255, 0.75);
  box-shadow: inset;
  font-weight: bolder;
}

.headerblank {
  height: 55px;
}

.Submit-btn {
  margin: 0;
}

.Clear-btn {
  margin: 0;
}

.time-input mat-icon, .time-input img {
  color: rgb(78, 78, 78);
  transform: scale(1);
  position: relative;
  float: right;
  height: 20px;
  width: 20px;
  margin-right: 3px;
  margin-top: -2px;
  cursor: pointer;
}
.time-input .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  width: max(2px, var(--mdc-shape-small, 2px)) !important;
}
.time-input button, .time-input input, .time-input optgroup, .time-input select, .time-input textarea {
  margin-left: -8px;
  margin-right: -8px;
}
.time-input .mat-timepicker-input {
  width: 75%;
}
.time-input .mat-mdc-form-field-subscript-wrapper {
  height: 1px;
}

.mdc-dialog__surface {
  overflow-y: clip !important;
}

.placeholder {
  background-color: #9b0000 !important;
  opacity: 0 !important;
}

.calender-input mat-icon, .calender-input img {
  color: rgb(78, 78, 78);
  transform: scale(1);
  position: relative;
  float: right;
  height: 20px;
  width: 20px;
  margin-right: 3px;
  margin-top: -2px;
  cursor: pointer;
}
.calender-input .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  width: max(2px, var(--mdc-shape-small, 2px)) !important;
}
.calender-input button, .calender-input input, .calender-input optgroup, .calender-input select, .calender-input textarea {
  margin-left: -8px;
  margin-right: -8px;
}
.calender-input .mat-datepicker-input {
  width: 75%;
}
.calender-input .mat-mdc-form-field-subscript-wrapper {
  height: 1px;
}

.calender-input-3icons mat-icon, .calender-input-3icons img {
  color: rgb(78, 78, 78);
  transform: scale(1);
  position: relative;
  float: right;
  height: 20px;
  width: 20px;
  margin-right: 3px;
  margin-top: -2px;
  cursor: pointer;
}
.calender-input-3icons .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  width: max(2px, var(--mdc-shape-small, 2px)) !important;
}
.calender-input-3icons button, .calender-input-3icons input, .calender-input-3icons optgroup, .calender-input-3icons select, .calender-input-3icons textarea {
  margin-left: -8px;
  margin-right: -8px;
}
.calender-input-3icons .mat-datepicker-input {
  width: 55%;
}
.calender-input-3icons .mat-mdc-form-field-subscript-wrapper {
  height: 1px;
}

.calender-range-input-3icons mat-icon, .calender-range-input-3icons img {
  color: rgb(78, 78, 78);
  transform: scale(1);
  position: relative;
  float: right;
  height: 20px;
  width: 20px;
  margin-right: 3px;
  margin-top: -2px;
  cursor: pointer;
}
.calender-range-input-3icons .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  width: max(2px, var(--mdc-shape-small, 2px)) !important;
}
.calender-range-input-3icons .mat-datepicker-input {
  width: 65%;
}
.calender-range-input-3icons .mat-mdc-form-field-subscript-wrapper {
  height: 1px;
}

.compact-input mat-icon, .compact-input img {
  color: rgb(78, 78, 78);
  transform: scale(1);
  position: relative;
  float: right;
  height: 20px;
  width: 20px;
  margin-right: 0px;
  margin-top: -25px;
  cursor: pointer;
}
.compact-input .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  width: max(2px, var(--mdc-shape-small, 2px)) !important;
}
.compact-input button, .compact-input input, .compact-input optgroup, .compact-input select, .compact-input textarea {
  margin-left: -8px;
  margin-right: -8px;
}
.compact-input .mat-mdc-form-field-subscript-wrapper {
  height: 1px;
}

.mat-mdc-form-field-subscript-wrapper {
  height: 1px;
}

.mat-mdc-row:hover .mat-mdc-cell {
  border-color: currentColor;
}

.demo-row-is-clicked {
  font-weight: bold;
}

::ng-deep .mat-datepicker-content .mat-calendar {
  width: 200px !important;
  height: 270px !important;
}

.mat-mdc-option {
  min-height: 30px !important;
  padding: 0 !important;
  margin-left: 3px !important;
  margin-right: 3px !important;
  margin-top: 3px !important;
  margin-bottom: 3px !important;
  align-items: left !important;
  line-height: 15px;
}

.matSelectImg {
  height: 25px;
  width: 25px;
  border-radius: 10px;
}

.mat-mdc-tab-body-content {
  height: 100%;
  overflow: hidden !important;
}

.menuIcon ion-icon {
  margin: auto;
  display: block;
  transform: scale(8);
  transition: all 300ms;
}

.IonListStyles {
  font-size: 12px;
  --min-height: 1px;
}
.IonListStyles ion-radio {
  padding: 0px;
  margin: 0px;
  margin-right: 8px;
  width: 15px;
  height: 15px;
}
.IonListStyles ion-item {
  font-size: 12px;
  --min-height: 1px;
}

::ng-deep .mat-mdc-tab.mdc-tab {
  height: 28px;
  flex-grow: 0;
}

.mat-mdc-tab.mdc-tab {
  font-size: 10;
  font-weight: 600;
  height: 28px !important;
}

.mat-mdc-tab-header .mdc-tab {
  height: 28px !important;
}

.mat-mdc-focus-indicator {
  position: relative;
  height: 2px !important;
}

.mat-mdc-tab, .mat-mdc-tab-link {
  background-color: #f0f0f0 !important;
}

.ion-grid-bg {
  background: #ececec;
  border-radius: 10px;
  margin-bottom: 10px;
  border-color: #fe5607;
  border-width: 0.005em;
  border-style: solid;
}

.ion-grid-heading {
  background: #ececec;
  border-radius: 5px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: medium;
  font-weight: bold;
  text-align: center;
  box-shadow: rgb(104, 104, 104) -2px 3px 6px 4px;
}

.ion-content-bg {
  --ion-background-color:#d1dfeb31;
}

ion-content {
  --ion-background-color:#4d80ad2f;
}